<template>
  <b-container class="mt-4">
    <b-row>
      <b-col cols="11">
        <h4>SFTP Group Create</h4>
      </b-col>
      <b-col>
        <b-button @click="submit" :disabled="loading || $v.$invalid" variant="primary" class="float-right">
          <feather type="save"></feather>
          Submit
        </b-button>
      </b-col>
    </b-row>
    <b-row v-if="loading">
      <b-col cols="12">
        <b-spinner></b-spinner>
      </b-col>
    </b-row>
    <b-row v-else class="mt-2">
      <b-col cols="12">
        <b-card>
          <b-row class="d-flex align-items-center mt-1">
            <b-col cols="2">
              <b>Server</b>
            </b-col>
            <b-col cols="6">
              <b-form-select v-model="server" :options="servers"></b-form-select>
            </b-col>
          </b-row>
          <b-row class="d-flex align-items-center mt-1">
            <b-col cols="2">
              <b>Name</b>
            </b-col>
            <b-col cols="6">
              <b-form-input v-model="group.name" :state="!$v.group.name.$invalid"/>
            </b-col>
          </b-row>
          <p class="card-text"><b>Permissions</b></p>
          <div class="ml-2">
            <b-row v-for="(permission, idx) in permissions" :key="idx" class="mt-1 d-flex align-items-center">
              <b-col cols="3">
                <b-form-group
                  :id="`label-permission-path-${idx}`"
                  label="Folder Path"
                  invalid-feedback="Ex: /test/simpsons/"
                  :label-for="`permission-path-${idx}`">
                  <b-form-input
                    :id="`permission-path-${idx}`"
                    v-model="permission.path"
                    type="text"
                    :state="!$v.permissions.$each[idx].path.$invalid"
                    placeholder="Enter filepath"
                    required/>
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-form-group
                  :id="`label-permission-role-${idx}`"
                  label="Role"
                  :label-for="`permission-role-${idx}`">
                  <b-form-select
                    :id="`permission-role-${idx}`"
                    v-model="permission.role"
                    :state="!$v.permissions.$each[idx].role.$invalid"
                    :options="roles"/>
                </b-form-group>
              </b-col>
              <b-col cols="1">
                <b-button @click="removePermission(idx)" variant="danger" size="md"><feather type="trash"/></b-button>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="1">
                <b-button @click="addNewPermission" variant="primary" size="md"><feather type="plus"/></b-button>
              </b-col>
            </b-row>
            <b-row class="mt-2" v-if="$v.permissions.$invalid && permissions.length === 0">
              <b-col cols="12">
                <p class="invalid-feedback" style="display: block">At least one permission is required</p>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { parseValidationsStr } from '@sword-health/input-validation';
import { minLength, required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

export default {
  name: 'SFTPGroupCreate',
  data() {
    return {
      servers: [
        { value: 'internal', text: 'Internal' },
        { value: 'external', text: 'External' },
      ],
      loading: false,
      server: 'internal',
      name: null,
      group: {
        name: '',
      },
      permissions: [],
      roles: [
        { value: 'admin', text: 'Admin' },
        { value: 'write', text: 'Write' },
        { value: 'read', text: 'Read' },
        { value: 'deny', text: 'Deny' },
      ],
    };
  },
  beforeMount() {
    if (!this.canAccessSftpUsersAndGroups) {
      this.$router.push('/not-found');
    }
  },
  computed: {
    ...mapGetters({
      canAccessSftpUsersAndGroups: 'User/canAccessSftpUsersAndGroups',
    }),
  },
  validations() {
    return {
      group: {
        name: {
          required,
          'Is not allow user name has: spaces and special characters (just dot and underscore)':
            v => !(v === '' || v === null) && v.match(/^[a-z][a-z0-9._]*$/i) !== null,
        },
      },
      permissions: {
        $each: {
          path: {
            isUnique(value) {
              if (value === '') {
                return true;
              }

              return this.permissions.filter(o => o.path === value).length <= 1;
            },
            ...parseValidationsStr({ required, validChars: /(^\/.*\/$|^\/$)/ }),
          },
          role: { required },
        },
        required,
        minLength: minLength(1),
      },
    };
  },
  methods: {
    addNewPermission() {
      this.permissions.push({ path: '', role: '' });
    },
    removePermission(idx) {
      this.permissions.splice(idx, 1);
    },
    async submit() {
      try {
        this.loading = true;

        const payload = {
          name: this.group.name,
          permissions: this.permissions.reduce((acc, { path, role }) => {
            acc[path] = role;
            return acc;
          }, {}),
        };

        await this.$store.dispatch('Sftp/Groups/createGroup', { server: this.server, payload });
        this.$noty.success('Group created successfully');
        this.$router.push(`/sftp/groups/${this.server}/${this.group.name}`);
      } catch (error) {
        this.$noty.error(error.message);
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
